<script>
  import { onMount } from "svelte";
  import { track } from "../tracking";

  let src;
  let footerLinkSrc;
  export let left;
  export let height = 30;
  export let width = null;

  onMount(() => {
    if (/Android|Windows /i.test(navigator.userAgent)) {
      src = "/blackout/img/mobile-youtube.svg";
      footerLinkSrc =
        "https://play.google.com/music/preview/Tt72dftm767c7mmxtytkdz6vley?play=1&u=0";
    }
    if (/Mac|iPhone|iPad|iPod /i.test(navigator.userAgent)) {
      src = "/blackout/img/apple-music.svg";
      footerLinkSrc =
        "https://music.apple.com/ua/album/blackout/1464075189?i=1464075192";
    }
  });
</script>

<style lang="scss">
  .left-position {
    position: absolute;
    left: 40px;
  }
  .desktop-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item {
      margin-right: 20px;
    }
  }
</style>

{#if window.innerWidth <= 830}
  <a
    on:click={() => track('link', 'applemusic_about')}
    href={footerLinkSrc}
    target="_blank"
    class={left ? 'left-position' : ''}>
    <img {src} width="{width}px" height="{height}px" alt="music-icon" />
  </a>
{/if}
{#if window.innerWidth > 830}
  <div class="desktop-wrapper">
    <a
      on:click={() => track('link', 'applemusic')}
      class="item"
      href="https://music.apple.com/ua/album/false-restraints-ep/1464075189?uo=4&ct=dash65670&app=music&at=10l4B8"
      target="_blank">
      <img src="/blackout/img/desktop-apple.svg" alt="music-icon" />
    </a>
    <a
      on:click={() => track('link', 'playmarket')}
      class="item"
      href="https://play.google.com/store/music/album?id=Berm7fib7gcsy32pbp6vswnjmw4"
      target="_blank">
      <img src="/blackout/img/desktop-google.svg" alt="music-icon" />
    </a>
    <a
      on:click={() => track('link', 'youtube')}
      class="item"
      href="https://music.youtube.com/playlist?list=OLAK5uy_nYn3LR6rTcTb-utmnmfMKtAg1P2fzybCE"
      target="_blank">
      <img src="/blackout/img/desktop-youtube.svg" alt="music-icon" />
    </a>
  </div>
{/if}
