import { TweenMax } from 'gsap';


export function addTextBlurEnter(element) {
    element.blurEnter = function(delay = 0) {
        return new Promise(onComplete => {
            TweenMax.to(element, .2, { delay, onComplete, opacity: 1, filter: 'blur(0)', translateY: 0, rotateX: 0 })
        });
    }

    element.reset = function() {
        TweenMax.set(element, { filter: 'blur(4px)', translateY: 25, rotateX: 25, opacity: 0 });
    }

    element.reset();
 }