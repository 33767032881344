import { TimelineLite } from 'gsap';

export function pulsation(targets, repeat = 0, onComplete = () => {}, maxScale = () => {}) {
    const timeline = new TimelineLite({ defaults: { transformOrigin: 'center center', translateX: 0, translateZ: 0, translateY: 0 }, onComplete });
    timeline.call(() => setTimeout(maxScale, 400))
            .to(targets, .8, { scale: 1.15 })
            .to(targets, .1, { scale: 1 })
            .to(targets, .1, { scale: 1.03 })
            .to(targets, .1, { scale: 1 })
            .to(targets, .1, { scale: 1.01 })
            .to(targets, .1, { scale: 1 })
            .repeat(repeat)
            .repeatDelay(.5);

    return timeline;
}
