import App from './App.svelte';
import ErrorPage from './components/404.svelte';

window.addEventListener('resize', resetHeight);

function resetHeight() {
	document.body.style.height = window.innerHeight + 'px';
}

resetHeight();

const app = window.errorPage ? new ErrorPage({ target: document.body }) : new App({
	target: document.body
});

export default app;
