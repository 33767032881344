<style lang="scss">
  .video-media {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      transform-origin: center center;

      @media(orientation:portrait) {
        height: 100vw;
        width: 100vh;
        transform: rotate(90deg);
      }
    }

    &__text {
      position: absolute;
      top: -2px;
      right: -30px;
      color: white;
      display: flex;
      font-size: 9px;
      line-height: 13px;
      flex-direction: column;
      $text: &;


      .first-text {
        margin-left: 5px;
      }

      .central-text {
        margin-left: 10px;
      }

      .third-text {
        margin-left: -5px;
      }

      &--half-passed {
        &.video-media__text--1 {
          right: -40px;

          span:nth-child(1) {
            margin-left: 5px;
          }
        }

        &.video-media__text--2 {
          right: -60px;

          span:nth-child(1) {
            height: 6px;
          }

          span:nth-child(3) {
            margin-left: 10px;
          }
        }

        &.video-media__text--3 {
          right: -45px;

          .central-text {
            margin-left: 5px;
          }
        }
      }


      &--2 {
        right: -50px;

        .third-text {
          margin-left: -2px;
        }
      }

      &--0 {
        right: -40px;

        .central-text {
          margin-left: 5px;
        }
      }
    }

    &__running-man {
      position: relative;
      padding-right: 15px;
      opacity: 1;
      margin-left: -40px;

      transform-origin: center center;

      @media(orientation:portrait) {
        margin-left: 0;
        transform: rotate(90deg);
      }

      &-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 5;

        &--visible {
          display: flex;
        }
      }
    }

    &__dot {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #F90D1B;
      position: absolute;
      right: 0;
      top: 12px;
    }

    .count-down-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 43%;
      top: 75%;
      transform: translate(-43%, -75%);
      font-family: Helvetica Neue;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.1em;
      color: white;

      @media(orientation: portrait) {
        right: 70%;
        top: 50%;
        transform: translate(-70%, -50%) rotate(90deg);
      }

      .count-down-dot {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: #F90D1B;
      }

      .count-down {
        display: inline-block;
        margin-left: 6px;
      }
    }
  }
</style>
<script>
  import { Howl } from 'howler';
  import { TweenLite, TimelineMax, TweenMax } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { addTextBlurEnter } from './text-blur-enter';
  import { strobDot } from './strob-dot';
  import RunningMan from './RunningMan.svelte';
  import { page, track, trackOnce } from '../tracking';

  const dispatch = createEventDispatcher();

  let currentTime;
  let lastTime = 0;
  let playing;
  let mediaVideo;
  let videoMediaRef;
  let runningManRef;
  let dotRef;
  let wordsStep = 0;
  let runningManBlockRef;
  let words = [];

  export let lowPowerMode;

  let audioInited = false;
  let videoInited = false;
  let videoEnded = false;

  let wordsRef;
  let coundDownWrapperRef;
  let countDownDotRef;
  let countDown = 3;
  let sound;
  let videoInterval;
  let playingSent;
  let inited = false;
  let videoTime = 0;
  let videoCounterStarted = false;
  let halfPassed = false;
  let playedPercent;
  let trackPercent;

  onMount(() => {
        TweenLite.set(coundDownWrapperRef, { zIndex: -1, opacity: 0 });
        addTextBlurEnter(wordsRef);
        window.stopVideo = stopVideo;
        window.resumeVideo = resumeVideo;
        sound = new Howl({
          src: ['/blackout/media/loop_wav.wav'],
          loop: true,
        });
        mediaVideo.load();

        mediaVideo.addEventListener('canplay', () => {
          console.log(mediaVideo.duration);
          // alert('CAN PLAY');
        });

        document.addEventListener('touchstart', playAndStopVideo);
  });


  function fadeVideo(inOrOut) {
    return new Promise(resolve => {
      let times = 0;

      if(videoInterval) {
        clearInterval(videoInterval);
      }

      videoInterval = setInterval(() => {

        if(times === 10) {
          resolve();
          return clearInterval(videoInterval);
        }

        if(inOrOut === 'in') {
          increaseVolume()
        } else {
          decreaseVolume();
        }

        times++;
      }, 25)
    });
  }

  function decreaseVolume() {
    if(mediaVideo.volume !== 0) {
      mediaVideo.volume -= 0.1
    }
  }

  function increaseVolume() {
    if(mediaVideo.volume !== 1) {
      mediaVideo.volume += 0.1
    }
  }

  function fadeInSound() {
    return new Promise((resolve) => {
      sound.play();
      sound.fade(0, 1, 250);
      sound.once('fade', resolve);
    })
  }

  function fadeOutSound() {
    return new Promise((resolve) => {
      sound.fade(1, 0, 250);
      sound.once('fade', () => {
        sound.stop();
        resolve();
      })
    })
  }

  export async function playAndStopVideo() {
    if(videoInited || lowPowerMode) {
      return;
    }
    videoInited = true;
    await mediaVideo.play();
    await mediaVideo.pause();
  }

  function strobDotInit() {
     const dotTimeline = strobDot(dotRef);
  }

  export function playAnimation() {
    return new Promise(async resolve => {
      TweenLite.set(videoMediaRef, { zIndex: 150 });
      try {
        page('/video');
        resetMan();
        strobDotInit();
        runningManRef.enterAnimation();
        mediaVideo.addEventListener('playing', () => {
          // alert('PLAYING');
          resolve();
          dispatch('playing');
        });
        mediaVideo.addEventListener('timeupdate', onTimeUpdate);
        mediaVideo.currentTime = 0;
        await mediaVideo.play();
        inited = true;
        playing = true;
      } catch(e) {
        console.log(e);
      }
    });
  }

  async function changeVideoTime(lastTime) {
      currentTime = lastTime - .5 < 0 ? 0 : lastTime - .5;
  }

  function toogleVideoCount() {
    TweenLite.set(coundDownWrapperRef, { zIndex: 1, opacity: 1 });

    const timeline = new TimelineMax({
      onComplete: () => {
        TweenLite.set(coundDownWrapperRef, { zIndex: -1, opacity: 0 });
      },
      onRepeat: () => {
        if (countDown === 0) {
          return;
        }

        countDown--;
      }
    })
    .repeat(3)
    .repeatDelay(1);
  }

  async function onTimeUpdate(data) {
    if(currentTime >= 3 && !playingSent) {
      playingSent = true;
      dispatch('start-monitoring');
    }

    if (!videoCounterStarted && Math.floor(currentTime) === 234) {
      trackPercent = 100;
      trackOnce('video_music', 'video_percent_100')
      videoCounterStarted = true;
      toogleVideoCount();
      strobDot(countDownDotRef);
      page('/makingof');
      dispatch('video-end');
    }

    playedPercent = Math.floor((currentTime / mediaVideo.duration) * 100)

    if (playedPercent >= 1 && !trackPercent) {
      trackPercent = 1;
      console.log('track 1');
      trackOnce('video_music', 'video_percent_001');
    }

    if (playedPercent >= 25 && trackPercent < 25) {
      trackPercent = 25;
      console.log('track 25');
      trackOnce('video_music', 'video_percent_025');
    }

    if (playedPercent >= 50 && trackPercent === 25) {
      trackPercent = 50;
      console.log('track 50');
      trackOnce('video_music', 'video_percent_050');
    }

    if (playedPercent >= 75 && trackPercent === 50) {
      trackPercent = 75;
      console.log('track 75');
      trackOnce('video_music', 'video_percent_075');
    }

    if(lastTime && currentTime >= lastTime) {
      changeVideoTime(lastTime)
    }

    if(currentTime >= 120) {
      if(halfPassed) {
        return;
      }
      wordsStep = 0;
      halfPassed = true;
    }
  }

  export function enterAnimation() {
    TweenLite.set(videoMediaRef, { zIndex: 15 });
  }

  function showMan() {
    TweenMax.to([runningManBlockRef, dotRef], .4, { opacity: 1 });
  }

  function resetMan() {
    TweenMax.set([runningManBlockRef, dotRef], { opacity: 0 });
  }

  export function playVideo() {
    return mediaVideo.play()
  }

  export function pauseVideo() {
    mediaVideo.pause();
  }


  export async function stopVideo() {
    if(lastTime || !playing) {
      return;
    }

    if(playing) {
      words = getWords();
    }

    track('video_music', 'window_dont_stup');
    fadeVideo('out');
    playing = false;
    showMan();
    wordsRef.blurEnter();
    await fadeInSound();
    mediaVideo.muted = true;
    lastTime = mediaVideo.currentTime;
    changeVideoTime(lastTime);
  }

  export function getWords() {
    if(!halfPassed) {
      const currentWords = [
        ['don`t', 'you', 'stop'],
        ['move', 'I`m your', 'satellite'],
        ['move', 'bit', 'by bit']
      ][wordsStep];

      wordsStep = wordsStep === 2 ? 0 : wordsStep + 1;
      return currentWords;
    }

    const additionalWords = [
      ['just', 'keep', 'moving'],
      ['', 'closer to', 'backstage'],
      ['you', 'almost', 'there'],
      ['move', 'a bit', 'more']
    ][wordsStep];

    wordsStep = wordsStep === 3 ? 0 : wordsStep + 1;
    return additionalWords;
  }


  export async function resumeVideo() {
    if(!lastTime || playing) {
      return;
    }

    try {
      console.log("RESUME VIDEO!");
      const newTime = lastTime;
      // await mediaVideo.pause();
      fadeOutSound();
      playing = true;
      wordsRef.reset();
      resetMan();
      lastTime = 0;
      currentTime = newTime;
      mediaVideo.muted = false;
      await fadeVideo('in');
      // await mediaVideo.play();
    }catch(e) {
      console.log(e);
      alert('RESUME VIDEO ERROR');
    }
  }
   export function hideContent() {
     if(!inited) {
       return;
     }

     const timeline = new TimelineMax();
     if(playing === false) {
       fadeOutSound();
     }
     timeline.to(videoMediaRef, 0.2, { opacity: 0, filter: "blur(0)" });
  }
  export function showContent() {
    if(!inited) {
       return;
    }

    const timeline = new TimelineMax();
    if(playing === false) {
       fadeInSound();
     }
    timeline.to(videoMediaRef, 0.2, { opacity: 1, filter: "blur(1)" });
  }

</script>

<div class="video-media" bind:this={videoMediaRef} on:click={() => dispatch('show-header-footer')}>
  <div class="video-media__running-man-container { playing === false ? 'video-media__running-man-container--visible' : ''}">
    <div class="video-media__running-man">
      <div bind:this={runningManBlockRef}>
        <RunningMan small="{true}" bind:this={runningManRef} />
      </div>
      <div class="video-media__dot" bind:this={dotRef}>
      </div>
      <div class="video-media__text video-media__text--{wordsStep} {halfPassed ? 'video-media__text--half-passed' : ''}" bind:this={wordsRef}>
        {#each words as word, i}
          <span class="{`${i === 1 ? 'central-text' : ''}
                         ${i === 0 && wordsStep === 2 ? 'first-text' : ''}
                         ${i === 2 && wordsStep === 2 ? 'third-text' : ''}`}">
            {word}
          </span>
        {/each}
      </div>
    </div>
  </div>
  <video
    src="/blackout/clip_with_bts.mp4"
    bind:this="{mediaVideo}"
    bind:currentTime
    preload="auto"
    playsinline
  ></video>
  <div class="count-down-wrapper" bind:this={coundDownWrapperRef}>
    <span class="count-down-dot" bind:this={countDownDotRef}></span>
    <span class="count-down">{countDown}</span>
  </div>
</div>
