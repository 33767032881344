<script>
    import { createEventDispatcher } from 'svelte';
    import { Pedometer } from '../pedometer';
    import _ from 'lodash';
    const podo = new Pedometer();
    let previoutStep;
    let step = 0;

    const dispatch = createEventDispatcher();

    const listener =  _.throttle(onDeviceMotion, 100);

    export function initMonitoring() {
        window.addEventListener("devicemotion", listener);
    }

    export function stopMonitoring() {
        window.removeEventListener("devicemotion", listener);
    }


    function onDeviceMotion(event) {
        if ((podo.acc_norm.length < 2) || (podo.stepArr.length < 2)) {
            podo.createTable(Math.round(2 / (event.interval / 1000)));
        } else {
            const norm = podo.computeNorm(event.accelerationIncludingGravity.x, event.accelerationIncludingGravity.y, event.accelerationIncludingGravity.z);
            podo.acc_norm.push(norm);

            podo.step = () => {
                step++;
                dispatch('step')
            };

            podo.stop = () => {
                dispatch('stop')
            };

            podo.update();
            podo.onStep(podo.acc_norm);
        }
    }
</script>
