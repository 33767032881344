<script>
  import { TimelineMax, TweenMax } from "gsap";
  import { createEventDispatcher } from "svelte";
  import { pulsation } from "./pulsation";
  import { strobDot } from "./strob-dot";
  import RunningMan from "./RunningMan.svelte";
  import { page, trackOnce } from '../tracking';

  let dotRef;
  let circleRef;
  let introRef;
  let circleBlurRef;
  let introCircleTitle;
  let introCircleText;
  let introCircleRunningMan;
  let runningManRef;
  let countDownTimeout;
  let countDown = 7;

  let mainTimeline;
  let dotTimeline;
  let introAnimated;

  const dispatch = createEventDispatcher();

  export function setOpacity() {
    TweenMax.set(introRef, { zIndex: 15, opacity: 1 });
    TweenMax.set([introCircleTitle, introCircleText], { opacity: 0 });
  }

  export function introPulsation() {
    return new Promise(resolve => {
      pulsation([circleBlurRef, circleRef], 0, resolve);
    });
  }

  export function pauseExecution() {
    if (mainTimeline) {
      mainTimeline.pause();
    }
    if (countDownTimeout) {
      countDownTimeout.pause();
    }
  }

  export function resumeExecution() {
    if (mainTimeline) {
      mainTimeline.play();
    }
    if (countDownTimeout) {
      countDownTimeout.play();
    }
  }

  export function enterAnimation() {
    return new Promise(resolve => {
      runningManRef.enterAnimation();
      TweenMax.set(circleBlurRef, { opacity: 1 });
      TweenMax.set([introCircleTitle, introCircleText], {
        filter: "blur(4px)",
        translateY: 25,
        rotateX: 25
      });
      page('/start');
      resolve();
    });
  }

  export function enterCircleInnerFigures() {
    const timeline = new TimelineMax({ onComplete: startIntroTimeline });
    timeline.to(introCircleTitle, 0.2, {
      opacity: 1,
      filter: "blur(0)",
      translateY: 0,
      rotateX: 0
    });
    timeline.to(
      introCircleText,
      0.2,
      { opacity: 1, filter: "blur(0)", translateY: 0, rotateX: 0 },
      0.08
    );
  }
  export function hideContent() {
    const timeline = new TimelineMax();
    timeline.to(introRef, 0.2, { opacity: 0, filter: "blur(0)" });
  }
  export function showContent() {
    const timeline = new TimelineMax();
    timeline.to(introRef, 0.2, { opacity: 1, filter: "blur(1)" });
  }
  function skipIntro() {
    if (!introAnimated) {
      return;
    }
    trackOnce('intro', 'tap_to_start');
    runTimeline(0, dotTimeline);
  }

  function startIntroTimeline() {
    introAnimated = true;
    dispatch("intro-animated");
    TweenMax.to(introCircleRunningMan, 0.3, { opacity: 1 });

    dotTimeline = strobDot(dotRef);

    countDownTimeout = new TimelineMax({
      onRepeat: () => {
        if (countDown === 0) {
          return;
        }
        countDown--;
      }
    })
      .repeat(7)
      .repeatDelay(1);

    runTimeline(6, dotTimeline);
  }

  function runTimeline(delay, dotTimeline) {
    if (mainTimeline) {
      mainTimeline.pause();
      mainTimeline = null;
    }

    const progress = { progress: 0 };

    mainTimeline = new TimelineMax({
      defaults: {
        delay
      },
      onComplete: () => {
        TweenMax.to(introRef, {
          duration: 0.1,
          delay: 0.1,
          opacity: 0,
          onComplete: () => {
            if (dotTimeline) {
              dotTimeline.pause();
            }
            if (countDownTimeout) {
              countDownTimeout.pause();
              countDownTimeout = null;
            }
            TweenMax.set(introRef, { zIndex: -1 });
            if (runningManRef) {
              runningManRef.exitAnimation();
            }
            dispatch("show-rotation-alert");
          }
        });
      }
    });

    mainTimeline
      .to([circleBlurRef, circleRef], 0, {
        backgroundColor: "#F90D1B",
        borderColor: "#F90D1B"
      })
      .to(circleBlurRef, 0, { filter: "blur(120px)" }, 0);
  }
</script>

<style lang="scss">
  @import "./mixins";
  .intro {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &-circle {
      @include circle-sizes;
      @include flex-column(center, center);

      border-radius: 50%;
      position: absolute;
      border: 4px solid white;
      box-sizing: border-box;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &__inner {
        @include circle-sizes;
        @include flex-column(center, center);
        position: relative;
      }

      &__title {
        @include title;
      }

      &__text {
        @include text;
      }

      &__blur {
        filter: blur(12px);
        transform: translate3d(0, 0, 0);
        overflow: visible;
        border-radius: 50%;
        opacity: 1;
      }

      &__container {
        position: relative;
        @include circle-sizes;

        transform: translate3d(0, 0, 0);
        overflow: visible;
        border-radius: 50%;

        @media (min-height: 380px) {
          margin-top: 30px;
        }
      }

      &__running-man {
        position: relative;
        padding-right: 15px;
        opacity: 0;
      }

      &__dot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #f90d1b;
        position: absolute;
        right: 0;
        top: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 10px;
        font-weight: bold;
        font-family: Helvetica Neue;
        line-height: 0%;
      }
    }
  }
</style>

<div class="intro" bind:this={introRef} on:click={skipIntro}>
  <div class="intro-circle__container">
    <div class="intro-circle intro-circle__blur" bind:this={circleBlurRef} />
    <div class="intro-circle" bind:this={circleRef} />
    <div class="intro-circle__inner">
      <h1 class="intro-circle__title" bind:this={introCircleTitle}>BLACKOUT</h1>
      <p class="intro-circle__text" bind:this={introCircleText}>
        First ever music video played by your steps
      </p>
      <div class="intro-circle__running-man" bind:this={introCircleRunningMan}>
        <RunningMan bind:this={runningManRef} />
        <div class="intro-circle__dot" bind:this={dotRef}>{countDown}</div>
      </div>
    </div>
  </div>
</div>
