<script>
  import Footer from "./Footer.svelte";
  import MusicIcon from "./MusicIcon.svelte";
  import { TweenMax, TimelineMax } from "gsap";
  import { onMount, createEventDispatcher } from "svelte";
  import { strobDot } from "./strob-dot";
  import { page, track } from '../tracking';

  let src = "/blackout/img/404_background.png";
  let mobSrc = "/blackout/img/404_mob_background.png";
  let aboutRef;
  let isOpenAboutPopup = true;
  export let onVideo;
  export let onDesktop;

  const dispatch = createEventDispatcher();

  let dotRef;
  let dotRef2;

  export function enterAnimation() {
    page('/about');
    TweenMax.set(aboutRef, { zIndex: 150 });
    TweenMax.to(aboutRef, 0.5, {
      opacity: 1,
      y: "0",
      onComplete: () => {
        document.body.style.overflowY = "auto";
        if (window.innerWidth < 768) {
          document.querySelector("body").style.position = "unset";
          document.querySelector("body").style.height = "inherit";
        }

        document.querySelector(".footer").style.opacity = "0";
        if (window.matchMedia("(orientation: portrait)").matches) {
          document.querySelector(".header-logo").style.display = "none";
        }
      }
    });
  }
  function toggleAboutPopup() {
    if (isOpenAboutPopup) {
      dispatch("hide-about-popup");
    }
  }
  export function exitAnimation() {
    return new Promise(resolve => {
      TweenMax.to(aboutRef, 0.5, {
        opacity: 0,
        y: "0%",
        onComplete: () => {
          TweenMax.set(aboutRef, { zIndex: -1 });
          document.body.style.overflowY = "hidden";
          document.querySelector(".footer").style.opacity = "1";

          if (window.innerWidth < 768) {
            document.querySelector("body").style.position = "fixed";
            document.querySelector("body").style.height = "100vh";
          }
          resolve();
        }
      });
    });
  }

  function pulsationAnimation() {
    const timeline = new TimelineMax();
    timeline
      .to(dotRef, 0.3, { opacity: 1 })
      .to(dotRef, 0.3, { opacity: 0 })
      .to(dotRef, 0.3, { opacity: 1 })
      .to(dotRef, 0.3, { opacity: 0 })
      .to(dotRef, 0.3, { opacity: 1 })
      .to(dotRef, 0.3, { opacity: 0 })
      .to(dotRef2, 0.3, { opacity: 1 })
      .to(dotRef2, 0.3, { opacity: 0 })
      .to(dotRef2, 0.3, { opacity: 1 })
      .to(dotRef2, 0.3, { opacity: 0 })
      .to(dotRef2, 0.3, { opacity: 1 })
      .to(dotRef2, 0.3, { opacity: 0 })
      .to(dotRef2, 0.3, { opacity: 0 })
      .repeat(-1);
  }

  function setFigureInitialPosition() {
    TweenMax.set(aboutRef, { opacity: 0, zIndex: -1, y: "0%" });
    TweenMax.set(dotRef, { opacity: 0 });
    TweenMax.set(dotRef2, { opacity: 0 });
  }

  onMount(async () => {
    setFigureInitialPosition();
    pulsationAnimation();
  });
</script>

<style lang="scss">
  .about {
    padding-bottom: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    .content {
      position: absolute;
      top: 50px;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;
      &-wrapper {
        margin-top: 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      -webkit-mask-image: -webkit-gradient(
        linear,
        left 10%,
        left top,
        from(black),
        to(rgba(0, 0, 0, 0))
      );
      @media (orientation: portrait) {
        top: 35px;
      }
      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 44px;
        @media (orientation: landscape) {
          display: none;
        }
        @media (min-width: 768px) {
          margin-top: -4px;
          display: flex;
          img {
            height: 3.5vh;
          }
        }
      }
      &-description {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        max-width: 290px;
        margin-right: 0;
        p {
          position: relative;
          margin: 1.4em 0;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .red-dot {
          position: absolute;
          top: 50%;
          transform: translate(0, 50%);
          margin-left: 8px;
          width: 8px;
          height: 8px;
          background: #f90d1b;
          border-radius: 50%;
        }

        .bold-link {
          position: relative;
          margin-top: 100px;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          @media (max-width: 768px) {
            margin-top: 0;
          }
          .red-dot {
            margin-left: -14px;
            transform: translate(0, -50%);
          }
          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
      &-team {
        display: flex;
        flex-direction: column;

        margin-top: 44px;
        font-style: italic;
        font-size: 12px;
        font-weight: 200;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        max-width: 320px;

        .bold {
          font-style: normal;
          font-weight: bold;
        }
        .team-item {
          margin: 10px 0;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:nth-child(3) {
            margin-bottom: 0;
          }
          .inner-block {
            &-title {
              display: block;
              margin-bottom: 20px;
            }
            &:nth-child(2) {
              margin-top: 15px;
            }
          }
        }
        .video {
          align-self: center;
          max-width: 245px;
          margin-top: 44px;
        }
      }
      .icon-wrapper {
        margin-top: 44px;
        margin-bottom: 52px;
        text-align: center;
      }
    }

    &--on-video {
      overflow: auto;
      .content {
        top: 0;
        width: 100vh;
        transform: rotate(90deg);
      }
    }

    &--on-desktop {
      overflow: hidden;
      
      .content {
        overflow: hidden
      };
    }

    .desktop-footer {
      display: flex;
      align-items: flex-end;
      position: relative;
      padding-bottom: 20px;
      width: 100%;

      &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      &__image {
        position: absolute;
        left: 40px;
      }

      &__copy {
        margin: 0;
        font-family: Helvetica Neue;
        font-size: 10px;
        line-height: 12px;
        color: #6a6d72;
      }

      &__menu {
        right: 40px;
        position: absolute;
        width: 35px;
        font-size: 12px;
        line-height: 14px;
        margin: 0;
        text-align: center;
        color: #ffffff;
        display: inline-block;
        cursor: pointer;

        &:hover {
          &:after {
            transform: scaleX(1.3);
          }
        }

        &:after {
          transition: all 0.3s ease-in-out;
          content: "";
          height: 4px;
          background-color: #fff;
          width: 100%;
          margin-top: 8px;
          display: inline-block;
          box-shadow: 0px 0px 6px 2px rgba(#ffffff, 0.6);
          transform: translateY(-32px);
        }
      }

      height: 19vh;
    }
  }
  @media (min-width: 768px) {
    .about {
      height: 85vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .content {
        flex-direction: row;
        justify-content: space-around;
        align-items: baseline;
        top: 0;
        .logo {
          height: 19vh;
          margin-top: 2px;
          opacity: 0;
        }
        &-wrapper {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          justify-content: center;
          margin-top: 0;
          .content-description {
            margin-right: 167px;
          }
        }
      }
      .desktop-footer {
        position: absolute;
        bottom: 39px;
      }
    }
  }
</style>

<div bind:this={aboutRef} class="about {onVideo ? 'about--on-video' : ''} {onDesktop ? 'about--on-desktop' : ''}">
  <div class="content">
    <div class="logo">
      <a href="https://themaunt.com/" target="_blank">
        <img src="/blackout/img/logo.svg" />
      </a>
    </div>
    <div class="content-wrapper">
      <div class="content-description">

        <p>
          Our constant yearning for stability and clarity
          <br />
          turns out to be an endless inner chase.
        </p>
        <p>
          Running with your phone
          <br />
          — > You embody the hero of the video himself.
        </p>
        <p>
          Physical movement immerses you in mostly abstract, still very tangible
          atmosphere of the hero’s unconsciousness. Aggressive, raw-expressive,
          unstable, distorted and epileptic.
          <br />
          A sense of painful obsession.
        </p>
        <p>
          Afterall, the only place you can get to is actually yourself
          <span class="red-dot" bind:this={dotRef} />
        </p>
        <div class="bold-link">
          <span class="red-dot" bind:this={dotRef2} />
          <a on:click={() => track('link', 'album')} href="http://smarturl.it/falserestraints" target="_blank">
            FALSE RESTRAINTS ALBUM
          </a>
        </div>
      </div>
      <div class="content-team">
        <div class="team-item">
          <span class="bold">Idea, creative & art direction</span>
          Nataliya Tachinskaya
        </div>
        <div class="team-item">
          <span class="bold">Web designer</span>
          Dima Keaton
        </div>
        <div class="team-item">
          <span class="bold">Web development</span>
          Mykhailo Saprykin, Pavel Ladan, Roman Konyk.
        </div>

        <div class="team-item video">
          <div class="inner-block">
            <span class="bold inner-block-title">Video</span>
            <div class="inner-item">Director Nataliya Tachinskaya</div>
            <div class="inner-item">DOP & edit Alex Stratienko</div>
            <div class="inner-item">Graphic Nick Kyrychenko</div>
            <div class="inner-item">
              Mapping Max Robotov & PHOTINUS Studio & KAMA NEW MEDIA students
            </div>
            <div class="inner-item">Colorist Oleg Kupshukov</div>
          </div>
          <div class="inner-block">
            <div class="inner-item">Producer Marta Melnychuk</div>

            <div class="inner-item">
              Production manager Kostiantyn Stetsenko
            </div>
            <div class="inner-item">Style Helen Przhonskaya</div>
          </div>
        </div>
      </div>
    </div>
    {#if window.innerWidth < 768}
      <div class="icon-wrapper">
        <MusicIcon />
      </div>
    {/if}
    {#if window.innerWidth < 768}
      <Footer relative={true} showAlways={true} />
    {/if}

  </div>

</div>
