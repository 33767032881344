<script>
  import RunningMan from "./RunningMan.svelte";
  import { track } from "../tracking";

  window.onload = function() {
    runningManRef.enterAnimation();
  };

  let runningManRef;
  let src = "/blackout/img/404_background.png";
  let mobSrc = "/blackout/img/404_mob_background.png";
</script>

<style lang="scss">
  .error-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    z-index: 999;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 19vh;
      img {
        height: 3.5vh;
      }
    }
    .error-composition {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 62vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-family: Helvetica Neue;
      font-style: normal;
      .error-count {
        position: relative;
        font-weight: 100;
        font-size: 220px;
        line-height: 258px;
        text-align: center;
        letter-spacing: 0.1em;
        .error-circle__blur {
          position: absolute;
          top: 50%;
          left: 49%;
          transform: translate(-50%, -50%);
          width: 244px;
          height: 244px;
          border-radius: 50%;
          background: #f90d1b;
          filter: blur(120px);
        }
        .error-circle {
          position: absolute;
          top: 50%;
          left: 49%;
          transform: translate(-50%, -50%);
          width: 244px;
          height: 244px;
          border-radius: 50%;
          background: #f90d1b;
        }
      }
      .error-description {
        text-decoration: none;
        position: relative;
        margin-top: 4vh;
        padding: 20px;
        font-family: Helvetica Neue;
        font-size: 1.75vh;
        text-align: center;
        letter-spacing: 0.04em;
        color: #ffffff;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          bottom: 10px;
          right: 50%;
          left: 50%;
          transform: translate(-50%);
          width: 32px;
          height: 4px;
          background: #fff;
          box-shadow: 0px 0px 6px 2px rgba(255, 255, 255, 0.6);
          transition: width 0.3s ease-in-out;
        }
        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }
    .error-footer {
      color: rgba(#ffffff, 0.4);
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 4vh;
      padding-bottom: 20px;

      .rights {
        font-size: 10px;
        line-height: 12px;
        margin: 0;
      }
    }
  }
  @media (orientation: landscape) {
    .logo {
      img {
        margin-top: 39px;
      }
    }
    .error-description {
      margin-top: 0;
    }
  }
  @media (max-width: 830px) {
    .error-wrapper {
      .logo {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: inherit;
        img {
          margin-top: 79px;
          height: auto;
          @media (orientation: landscape) {
            margin-top: 39px;
          }
        }
      }
      .error-composition {
        .error-count {
          font-size: 140px;
          .error-circle {
            width: 165px;
            height: 165px;
            background: #f90d1b;
          }
          .error-circle__blur {
            transform: translate(-50%, -50%);
            background: #f90d1b;
            filter: blur(120px);
          }
        }
        .error-description {
          margin-top: 4vh;
          font-size: 12px;
          @media (orientation: landscape) {
            margin-top: -7vh;
          }
        }
      }
      .error-footer {
        position: absolute;
        bottom: 12px;
        padding-bottom: 0;
      }
    }
  }
</style>

<div
  class="error-wrapper"
  style="background: url('{window.innerWidth < 768 ? mobSrc : src}');
  background-size: cover">
  <div class="logo">
    <a
      on:click={() => track('link', 'logo', '/404')}
      href="https://themaunt.com/"
      target="_blank">
      <img src="/blackout/img/logo.svg" />
    </a>
  </div>
  <div class="error-composition">
    <div class="error-count">
      404
      <div class="error-circle__blur" />
      <div class="error-circle">
        <RunningMan bind:this={runningManRef} centered={true} />
      </div>
    </div>

    <a
      on:click={() => track('link', 'main_page')}
      href="https://blackout.themaunt.com"
      class="error-description">
      Bit by bit move to Main page
    </a>
  </div>
  <div class="error-footer">
    <p class="rights">©THE MAUNT. 2020. All rights reserved.</p>
  </div>
</div>
