<script>
  import { TimelineMax, TweenLite } from "gsap";
  import { onMount, createEventDispatcher } from "svelte";
  import { pulsation } from "./pulsation";
  import { trackOnce } from '../tracking';

  let circleRef;
  let blurRef;
  let tapRef;
  let textRef;
  let runRef;
  let tapContainerRef;
  let secondTapRef;
  let secondTextRef;
  let timeline;
  let asked;

  let animating;

  export let videoRef;

  let entered;
  let shouldAskAccelerometer;

  export function hideContent() {
    const timeline = new TimelineMax();
    timeline.to(tapContainerRef, 0.2, { opacity: 0, filter: "blur(0)" });
  }
  export function showContent() {
    const timeline = new TimelineMax();
    timeline.to(tapContainerRef, 0.2, { opacity: 1, filter: "blur(1)" });
  }

  onMount(async () => {
    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      shouldAskAccelerometer = await DeviceMotionEvent.requestPermission()
        .then(response => response !== "granted")
        .catch(e => true);
    }
  });

  const dispatch = createEventDispatcher();

  async function animateTapToStart() {
    animating = true;

    await rotateOut([tapRef, textRef]);
    await rotateIn([secondTapRef, secondTextRef]);
    timeline.play();
    shouldAskAccelerometer = false;
    animating = false;
  }

  async function accessAccelerometer() {
    if (animating || asked) {
      return;
    }
    asked = true;
    trackOnce('intro', 'tap_to_allow');

    if (timeline) {
      timeline.pause();
    }

    if(typeof DeviceMotionEvent === "undefined") {
      trackOnce('intro', 'orientation_notsupported');
    }

    if (shouldAskAccelerometer) {
      return await DeviceMotionEvent.requestPermission()
        .then(response => {
          if (response !== "granted") {
            return trackOnce('intro', 'orientation_deny');
          }
          return onApproved();
        })
        .catch(() => {
          alert("Для коректної роботи необхідний доступ до акселерометра");
          return trackOnce('intro', 'orientation_deny');
        });
    }

    onApproved();
  }

  function onApproved() {
    if(animating) {
        return;
    }
    trackOnce('intro', 'orientation_allow');
    animating = true;
    TweenLite.to([circleRef, blurRef], 0.1, { scale: 1 });
    toRun();
  }

  function rotateOut([firstElement, secondElement]) {
    return new Promise(resolve => {
      TweenLite.to(firstElement, 0.2, { translateY: -25, rotateX: -25 });
      TweenLite.to(firstElement, 0.15, {
        opacity: 0,
        delay: 0.1,
        onComplete: resolve
      });

      if (secondElement) {
        TweenLite.to(secondElement, 0.2, {
          translateY: -25,
          rotateX: -25,
          delay: 0.05
        });
        TweenLite.to(secondElement, 0.15, { opacity: 0, delay: 0.15 });
      }
    });
  }

  function rotateIn([firstElement, secondElement]) {
    return new Promise(resolve => {
      TweenLite.to(firstElement, 0.2, {
        opacity: 1,
        translateY: 0,
        rotateX: 0,
        filter: "blur(0)",
        delay: 0.1,
        onComplete: resolve
      });
      if (secondElement) {
        TweenLite.to(secondElement, 0.2, {
          opacity: 1,
          translateY: 0,
          rotateX: 0,
          delay: 0.15
        });
      }
    });
  }

   async function toRun() {
        await rotateOut([tapRef, textRef]);
        await rotateIn([runRef]);

        dispatch('hide-content')
        TweenLite.to(runRef, .5, { opacity: 0, onComplete: () => {
          TweenLite.to([circleRef, blurRef], .5, { opacity: 0, scale: .6 });
        }});

        animating = false;
        TweenLite.to(tapContainerRef, .3, {
            delay: 1,
            opacity: 0,
            onComplete: () => {
                TweenLite.set(tapContainerRef, { zIndex: -1 });
                animating = false;
                dispatch('allowed');
            }
        });
    }
  export function enterAnimation() {
    if (entered) {
      return;
    }

    entered = true;

    // TweenLite.set([secondTapRef, secondTextRef, runRef], { translateY: 25, rotateX: 25, filter: 'blur(4px)' })
    // TweenLite.to([secondTapRef, secondTextRef], .2, { translateY: 0, rotateX: 0, filter: 'blur(0)' })

    TweenLite.set(tapContainerRef, { zIndex: 5 });
    TweenLite.to(tapContainerRef, 0.3, {
      opacity: 1,
      onComplete: () => {
        timeline = pulsation([circleRef, blurRef], -1);
      }
    });
  }
</script>

<style lang="scss">
  @import "./mixins";
  .tap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    opacity: 0;

    &-circle {
      border-radius: 50%;
      position: absolute;
      border: 4px solid white;
      box-sizing: border-box;
      bottom: 0;
      left: 0;
      right: 0;

      @include circle-sizes;
      @include flex-column(center, center);

      &__inner {
        @include flex-column(center, center);
        position: absolute;
      }

      &__title {
        @include title;

        margin: 0;
        opacity: 1;
        text-align: center;

        .tap-circle__inner--should-ask-accelerometer & {
          opacity: 0;
          transform: translateY(25px) rotateX(50deg);
        }

        &--run {
          opacity: 0;
          transform: translateY(25px) rotateX(50deg);
        }
      }

      &__text {
        font-family: Helvetica Neue;
        font-size: 12px;
        line-height: 14px;
        margin: 0 24px 24px;
        color: #ffffff;
        opacity: 1;
        text-align: center;

        .tap-circle__inner--should-ask-accelerometer & {
          opacity: 0;
          transform: translateY(25px) rotateX(50deg);
        }
      }

      &__blur {
        margin: 4px;
        filter: blur(12px);
        transform: translate3d(0, 0, 0);
        overflow: visible;
        border-radius: 50%;
        opacity: 1;
      }

      &__container {
        position: relative;
        @include circle-sizes;
        @include flex-column(center, center);

        transform: translate3d(0, 0, 0);
        overflow: visible;
        border-radius: 50%;

        @media (min-height: 380px) {
          margin-top: 30px;
        }

        border-radius: 50%;
        display: flex;
      }
    }
  }
</style>

<div class="tap" bind:this={tapContainerRef}>
  <div class="tap-circle__container" on:click={accessAccelerometer}>
    <div class="tap-circle tap-circle__blur" bind:this={blurRef} />
    <div class="tap-circle" bind:this={circleRef} />
    <div class="tap-circle__inner">
      <h2 class="tap-circle__title" bind:this={tapRef}>TAP</h2>
      <p class="tap-circle__text" bind:this={textRef}>
        {#if shouldAskAccelerometer}to allow accelerometer{:else}to start{/if}
      </p>
    </div>
    <!-- <div class="tap-circle__inner { shouldAskAccelerometer ? 'tap-circle__inner--should-ask-accelerometer' : ''}">
            <h2 class="tap-circle__title" bind:this={secondTapRef}>
                TAP
            </h2>
            <p class="tap-circle__text" bind:this={secondTextRef}>
                to start
            </p>
        </div> -->
    <div class="tap-circle__inner">
      <h2 class="tap-circle__title tap-circle__title--run" bind:this={runRef}>
        MOVE
      </h2>
      <p class="tap-circle__text" />
    </div>
  </div>
</div>
