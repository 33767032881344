<script>
  import { TweenMax } from "gsap";
  import { onMount, createEventDispatcher } from "svelte";
  import { track } from '../tracking';

  let aboutRef;
  let logoRef;
  let headerRef;
  let shown = false;
  let isOpenAboutPopup = false;
  let visible;

  export let onVideo;
  export let introAnimated;
  export let page;

  const dispatch = createEventDispatcher();

  export let showAlways;
  export let onlyLogo;

  export function enterAnimation() {
    TweenMax.to(aboutRef, 0.3, { opacity: 1 });
  }
  function checkOrientation() {
    const match = window.matchMedia("(orientation: landscape)");
    if (match.matches && isOpenAboutPopup) {
      document.querySelector(".header-logo").style.display = "block";
    } else if (!match.matches && isOpenAboutPopup) {
      document.querySelector(".header-logo").style.display = "none";
    }
  }

  function toggleAboutPopup() {
    if (!visible || !introAnimated) {
      return;
    }

    track('link', 'about', page);

    if (!isOpenAboutPopup) {
      dispatch("show-about-popup");
    } else {
      document.querySelector(".header-logo").style.display = "block";
      dispatch("hide-about-popup");

      if (shown) {
        hideEntered(true);
      }
    }

    isOpenAboutPopup = !isOpenAboutPopup;
  }

  function hideEntered(forceHide) {
    if (isOpenAboutPopup && !forceHide) {
      return;
    }

    if (shown) {
      clearTimeout(shown);
    }

    TweenMax.to([headerRef, aboutRef, logoRef], 0.3, {
      opacity: 0,
      onComplete: () => {
        shown = null;
        TweenMax.set(headerRef, { zIndex: -1 });
      }
    });
  }

  export function enterWithExit() {
    if (shown) {
      return hideEntered();
    }

    TweenMax.set(headerRef, { zIndex: 160 });

    TweenMax.to([headerRef, aboutRef, logoRef], 0.3, {
      opacity: 1,
      onComplete: () => (visible = true)
    });

    shown = setTimeout(hideEntered, 4000);
  }

  export function enterLogo() {
    return new Promise(onComplete => {
      TweenMax.to(logoRef, 0.3, {
        opacity: 1,
        onComplete: () => {
          onComplete();
          visible = true;
        }
      });
    });
  }

  export function exitAnimation() {
    TweenMax.to(headerRef, 0.3, {
      opacity: 0,
      onComplete: () => (visible = false)
    });
  }
  onMount(() => {
    window.addEventListener("resize", checkOrientation);
    checkOrientation();
  });
</script>

<style lang="scss">
  .header-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &.on-video {
      @media (orientation: portrait) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .header {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    flex-direction: column;

    .on-video & {
      @media (orientation: portrait) {
        flex-direction: row-reverse;
        align-items: flex-start;
        transform: rotate(90deg);
        width: 100vh;
        justify-content: space-between;
        margin-left: 8px;
      }
    }

    @media (orientation: landscape) {
      flex-direction: row-reverse;
      padding: 18px;
      justify-content: space-between;
    }
  }

  .header-menu {
    margin-top: 20px;
    width: 35px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    &:after {
      content: "";
      height: 4px;
      background-color: #fff;
      width: 100%;
      margin-top: 8px;
      display: inline-block;
      box-shadow: 0px 0px 6px 2px rgba(#ffffff, 0.6);
      transition: all 0.4s ease-in-out;
    }
    opacity: 0;

    &--close {
      &:after {
        transform: translateY(-32px);
      }
    }

    .on-video & {
      @media (orientation: portrait) {
        margin: 0 18px;
      }
    }

    @media (orientation: landscape) {
      margin: 0;
    }
  }
  .header-logo {
    display: block;
    margin-top: 30px;
    opacity: 0;

    .on-video & {
      @media (orientation: portrait) {
        margin: 0 18px;
      }
    }

    @media (orientation: landscape) {
      margin: 0;
    }
  }
  .showAlways {
    position: relative;
    opacity: 1;
  }
  .only-logo {
    margin-top: 60px;
    justify-content: center;
    .header-menu {
      display: none;
      @media (orientation: landscape) {
        display: block;
      }
    }
  }
</style>

<div class="header-wrapper {onVideo ? 'on-video' : ''}">
  <div class="header {onlyLogo ? 'only-logo' : ''}" bind:this={headerRef}>
    <span
      class="header-menu {showAlways ? 'showAlways' : ''}
      {isOpenAboutPopup ? 'header-menu--close' : ''}"
      bind:this={aboutRef}
      on:click={toggleAboutPopup}>
      {isOpenAboutPopup ? 'Close' : 'About'}
    </span>
    <a
      on:click={() => track('link', 'logo', page)}
      href="https://themaunt.com/"
      class="header-logo {showAlways ? 'showAlways' : ''}"
      target="_blank"
      bind:this={logoRef}>
      <img src="/blackout/img/logo.svg" alt="" />
    </a>
  </div>
</div>
