<script>
  import { createEventDispatcher } from "svelte";
  import { TweenMax } from "gsap";

  let copyRightRef;
  let footerRef;
  let footerContainerRef;
  let shown;

  export let onVideo;
  export let showAlways;
  export let relative;

  const dispatch = createEventDispatcher();

  export function hideEntered() {
    if (shown) {
      clearTimeout(shown);
    }

    TweenMax.to([footerRef], 0.3, {
      opacity: 0,
      onComplete: () => {
        shown = null;
        TweenMax.set([footerRef, footerContainerRef], { zIndex: -1 });
      }
    });
  }

  export function enterAnimation() {
    return new Promise(onComplete => {
      TweenMax.to(footerRef, 0.3, { opacity: 1, onComplete });
    });
  }

  function triggerClose() {
    if (!onVideo) {
      return;
    }

    dispatch("exit");
  }

  export function enterWithExit() {
    if (shown) {
      return hideEntered();
    }

    TweenMax.set([footerRef, footerContainerRef], { zIndex: 150 });

    TweenMax.to([footerRef], 0.3, { opacity: 1 });

    shown = setTimeout(hideEntered, 4000);
  }

  export function exitAnimation() {
    TweenMax.to(footerRef, 0.3, {
      opacity: 0,
      onComplete: () => TweenMax.set(footerRef, { zIndex: -1 })
    });
  }
</script>

<style lang="scss">
  .footer {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 10px;
    line-height: 12px;
    bottom: 0;
    height: 3vh;

    &.on-video {
      @media (orientation: portrait) {
        transform: rotate(90deg);
        position: absolute;
        width: 100vh;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        bottom: 0;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 12px;
      }
    }
  }

  .copyrights {
    color: rgba(#ffffff, 0.4);
    opacity: 0;
    margin: 0;
    position: absolute;
    z-index: 150;
  }

  .relative {
    position: relative;
    padding-bottom: 20px;
  }
  .show-always {
    opacity: 1;
  }
</style>

<div
  bind:this={footerContainerRef}
  on:click={triggerClose}
  class="footer {relative ? 'relative' : ''}
  {onVideo ? 'on-video' : ''}">
  <p class="copyrights {showAlways ? 'show-always' : ''}" bind:this={footerRef}>
    ©THE MAUNT. 2020. All rights reserved.
  </p>
</div>
