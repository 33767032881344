class Kalman {
    constructor() {
        this.G  = 1; // filter gain
        this.Rw = 1; // noise power desirable
        this.Rv = 10; // noise power estimated

        this.A = 1;
        this.C = 1;
        this.B = 0;
        this.u = 0;
        this.P = NaN;
        this.x = NaN; // estimated signal without noise
        this.y = NaN; //measured
    }

    //signal: signal measured
    onFilteringKalman(ech) {
		this.y = ech;

		if (isNaN(this.x)) {
			this.x = 1/this.C * this.y;
			this.P = 1/this.C * this.Rv * 1/this.C;
		}
		else {
			// Kalman Filter: Prediction and covariance P
			this.x = this.A*this.x + this.B*this.u;
			this.P = this.A * this.P * this.A + this.Rw;
			// Gain
			this.G = this.P*this.C*1/(this.C*this.P*this.C+this.Rv);
			// Correction
			this.x = this.x + this.G*(this.y-this.C*this.x);
			this.P = this.P - this.G*this.C*this.P;
		};
		return this.x;
	};

	setRv(Rv){
        //signal: signal measured
		this.Rv = Rv;
	};
}

export class Pedometer {
    constructor() {
        this.acc_norm = new Array(); // amplitude of the acceleration
        this.var_acc   = 0.; // variance of the acceleration on the window L
        this.min_acc   = 1./0.;  // minimum of the acceleration on the window L
        this.max_acc   = -1./0.; // maximum of the acceleration on the window L
        this.threshold = -1./0.; // threshold to detect a step
        this.sensibility = 1./30.;  // sensibility to detect a step

        this.countStep = 0;           // number of steps
        this.stepArr   = new Array(); // steps in 2 seconds

        this.weight    = 70; // weight of the pedestrian
        this.stepSize  = 50; // step size of the pedestrian (cm)
        this.distance  = 0;  // total distance (cm)
        this.calory    = 0;  // calory burned (C)
        this.speed     = 0;  // instantaneous speed of the pedestrian (m/s)
        this.meanSpeed = 0;  // mean speed of the pedestrian (m/s)

        this.isGPSEnabled  = false; //enable GPS measurement
        this.isGPSReceived = false; //GPS receives data
        this.GPSposition; // GPS data
        this.GPSlat = 0;
        this.GPSlon = 0;
        this.GPStimeSt = 0;
        this.distGPS = 0; // distance between 2 acquisitions
        this.timeGPS = 0; // duration between 2 acquisitions
        this.idGPS = 0; // GPS handler

        this.filter = new Kalman();
    }

    setCountStep(count) {
		this.countStep = count;
	};

    setWeight(weight) {
		this.weight = weight;
    };

    setCalory(calory) {
		this.calory = calory;
	};

	setStepSize(stepSize) {
		this.stepSize = stepSize;
	};

	setMeanSpeed(meanSpeed) {
		this.meanSpeed = meanSpeed;
	};

	setSensibility(sensibility) {
		this.sensibility = sensibility;
	};

	setIsGPSEnabled(isGPSEnabled) {
		this.isGPSEnabled = isGPSEnabled;
	};

	setIsGPSReceived(isGPSReceived) {
		this.isGPSReceived = isGPSReceived;
	};

	setGPSposition(GPSposition) {
		this.GPSposition = GPSposition;
	};

	setGPSlat(GPSlat) {
		this.GPSlat = GPSlat;
	};

	setGPSlon(GPSlon) {
		this.GPSlon = GPSlon;
	};

	setGPStimeSt(GPStimeSt) {
		this.GPStimeSt = GPStimeSt;
	};

	setIdGPS(idGPS) {
		this.idGPS = idGPS;
	};

	// initialization of arrays
	createTable(lWindow) {
		this.acc_norm = new Array(lWindow);
		this.stepArr = new Array(lWindow);
	};

	// update arrays
	update() {
		this.acc_norm.shift();
	};

	// compute norm of the acceleration vector
	computeNorm(x,y,z) {
		var norm = Math.sqrt(Math.pow(x,2)+Math.pow(y,2)+Math.pow(z,2));
		var norm_filt = this.filter.onFilteringKalman(norm);

		return norm_filt/9.80665;
	};

	// seek variance
	varAcc(acc) {
		var moy  = 0;//mean
		var moy2 = 0;//square mean
		for (var k = 0; k < acc.length-1; k++) {
			moy += acc[k];
			moy2 += Math.pow(acc[k],2);
		};
		this.var_acc = (Math.pow(moy,2) - moy2)/acc.length;
		if (this.var_acc - 0.5 > 0.) {
				this.var_acc -= 0.5;
		};
		if (isNaN(this.var_acc) == 0) {
			this.filter.setRv(this.var_acc);
		}
		this.setSensibility(1./30.);
	};

	// seek minimum
	minAcc(acc) {
		var mini = 1./0.;
		for (var k = 0; k < acc.length; k++) {
			if (acc[k] < mini)
			{
				mini = acc[k];
			};
		};
		return mini;
	};

	// seek maximum
	maxAcc(acc) {
		var maxi = -1./0.;
		for (var k = 0; k < acc.length; k++) {
			if (acc[k] > maxi)
			{
				maxi = acc[k];
			};
		};
		return maxi;
	};

	// compute the threshold
	setThreshold(min, max) {
		this.threshold = (min+max)/2;
  };

  onStep(acc) {

		this.varAcc(acc);

    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    this.min_acc = iOS ? 0.7 : 0.67;
    this.max_acc = 1.4;

		// this.max_acc = this.max_acc > this.min_acc * 2 ? this.min_acc : this.min_acc;

		this.setThreshold(this.min_acc, this.max_acc);

		var diff = this.max_acc - this.min_acc;

		var isSensibility   = (Math.abs(diff) >= this.sensibility); // the acceleration has to go over the sensibility
		var isOverThreshold = ((acc[acc.length-1] >= this.threshold) && (acc[acc.length-2] < this.threshold));// if the acceleration goes over the threshold and the previous was below this threshold
		var isValidStep     = this.stepArr[this.stepArr.length-1] === 0;

		if (isSensibility && isOverThreshold && isValidStep) {
			this.countStep++;
			this.stepArr.push(1);
			this.stepArr.shift();
			this.step();
		} else {
			this.stepArr.push(0);
			this.stepArr.shift();
			this.stop();
		};
	};

	// Compute total distance
	setDistance() {
		this.distance = this.countStep * this.stepSize;//cm
	};

	setDistanceGPS() {
		var lat1 = this.GPSposition[0].coords.latitude*Math.PI/180.,
			lat2 = this.GPSposition[1].coords.latitude*Math.PI/180.,
			lon1 = this.GPSposition[0].coords.longitude*Math.PI/180.,
			lon2 = this.GPSposition[1].coords.longitude*Math.PI/180.;

		this.distGPS = Math.acos(Math.sin(lat1)*Math.sin(lat2)+Math.cos(lat1)*Math.cos(lat2)*Math.cos(lon1-lon2));

		this.timeGPS = Math.abs(this.GPSposition[0].timestamp - this.GPSposition[1].timestamp);

		if ((isNaN(this.distance) == 0) && (isNaN(this.distGPS) == 0)){
			if  (this.timeGPS <= 60000) {
				this.distance = this.distance + this.distGPS;
			};
		} else {
			this.distance = this.distGPS;
		};
	};

	// Compute instantaneous speed on 2 seconds
	onSpeed() {
		var stepin2s = 0;
		this.speed = 0;
		for (var k = 0; k < this.stepArr.length ; k++) {
			stepin2s += this.stepArr[k];
		};
		var distin2s = stepin2s * this.stepSize;
		var speedAcc = (distin2s/100.)/2.; //m/s


		var speedGPS = 0;
		if (Boolean(this.isGPSEnabled) && Boolean(this.isGPSReceived)) {
			if ((this.timeGPS < 6000) && (isNaN(this.distGPS) == 0)  && (isNaN(speedAcc) == 0) && (isNaN(this.timeGPS) == 0)){
				if (this.timeGPS > 0) {
					speedGPS = this.distGPS / this.timeGPS;
				};
				this.speed = (speedGPS + speedAcc)/2;
			};
		} else {
			this.speed =  speedAcc;
		};

		// Mean Speed
		if ((this.stepArr[this.stepArr.length-1] !== 0) && ((this.speed !== 0) && (isNaN(this.speed) == 0))) {
			if (isNaN(this.meanSpeed) == 0){
				this.meanSpeed = (this.meanSpeed * (this.countStep-1) + this.speed)/this.countStep;
			} else {
				this.meanSpeed = this.speed;
			};
		};
	};

	// Compute calories burned
	onCalory() {
		if ((isNaN(this.speed) == 0) && (isNaN(this.calory) == 0)) {
			this.calory += this.speed * this.weight / 400;
		};
	};
}


export function getGPSLocation(podo) {
	// Request a position. We accept positions whose age is not
	// greater than 10 minutes. If the user agent does not have a
	// fresh enough cached position object, it will automatically
	// acquire a new one.
	return new Promise((resolve, reject) => {

		let id;
		const dataGPS = new Array();

		if (podo.isGPSEnabled == true) {
			const options = {
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 600000
			};

			function successCallback(position) {
				resolve();
				dataGPS.push(position);
				if (dataGPS.length >= 2) {
					podo.setIsGPSReceived(true);
					dataGPS.shift();
				}
				podo.setGPSposition(dataGPS);
				podo.setGPSlat(position.coords.latitude);
				podo.setGPSlon(position.coords.longitude);
				podo.setGPStimeSt(position.timestamp);
			};

			function errorCallback(error) {
				console.log("ERROR ->", error);
				podo.setIsGPSReceived(false);
				reject();
			};

			id = navigator.geolocation.watchPosition(successCallback, errorCallback, options);
			podo.setIdGPS(id);
		};

	})
};

