const TRACKING_ID = 'UA-168063815-1';

const trackingCache = {};

export function page(page_path) {
    if (!PRODUCTION) {
        return console.log('trackPage -> ', page_path);
    }
    gtag('config', TRACKING_ID, {
        page_path,
        transport_type: 'beacon'
    });

}

export function track(event_action, event_category, event_label, event_value) {
    if (!PRODUCTION) {
        return console.log('trackEvent -> ', event_action, {
            event_category,
            event_label,
            event_value
        });
    }
    gtag('event', event_action, {
        event_category,
        event_label,
        event_value
    });
}

export function trackOnce(event_action, event_category, event_label, event_value) {
    const key = `${event_category}:${event_action}`;
    if (trackingCache.hasOwnProperty(key)) {
        return;
    }

    track(event_category, event_action, event_label, event_value);
    trackingCache[key] = true;
}
