<script>
  import VideoBackground from "./components/VideoBackground.svelte";
  import Header from "./components/Header.svelte";
  import Footer from "./components/Footer.svelte";
  import RotateAlert from "./components/RotateAlert.svelte";
  import Intro from "./components/Intro.svelte";
  import Tap from "./components/Tap.svelte";
  import { onMount, tick } from "svelte";

  import StepsMonitor from "./components/StepsMonitor.svelte";
  import VideoMedia from "./components/VideoMedia.svelte";
  import Desktop from "./components/Desktop.svelte";
  import About from "./components/About.svelte";

  let rotationAlertRef;
  let videoBackgroundRef;
  let headerRef;
  let footerRef;
  let introRef;
  let mediaVideoRef;
  let videoWithPedometerRef;
  let tapRef;
  let stopCount = 0;
  let walking;
  let playing;
  let aboutRef;
  let allowed;
  let introAnimated;
  let desktopRef;
  let lowPowerMode = false;
  let lowPowerRef;
  let stopping;
  let resuming;
  let onDesktop = window.matchMedia("(min-width: 1024px)").matches;
  let page = '/start';

  function showRotationAlert() {
    const mql = window.matchMedia("(orientation: portrait)");
    if (mql.matches) {
      return rotationAlertRef.enterAnimation();
    }

    onRotateFinished();
  }

  function showAboutPopup() {
    if(introRef) {
      introRef.hideContent();
    }
    if(rotationAlertRef) {
      rotationAlertRef.hideContent();
    }
    if(tapRef) {
      tapRef.hideContent();
    }
    if(mediaVideoRef) {
      mediaVideoRef.hideContent();
    }
    aboutRef.enterAnimation();
    pauseExecution();
  }

  function pauseExecution() {
    if(introRef) {
      introRef.pauseExecution();
    }
    if(rotationAlertRef) {
      rotationAlertRef.pauseExecution();
    }
    if (allowed) {
      playing = false;
      mediaVideoRef.pauseVideo();
    }
  }

  async function hideAboutPopup() {
    if (allowed) {
      footerRef.hideEntered();
      mediaVideoRef.showContent();
      await aboutRef.exitAnimation();
      return resumeExecution();
    }
    await aboutRef.exitAnimation();
    if(introRef) {
      introRef.showContent();
    }
    if(tapRef) {
      tapRef.showContent();
    }
    if(rotationAlertRef) {
      rotationAlertRef.showContent();
    }

    resumeExecution();
  }

  async function resumeExecution() {
    if (allowed) {
      await mediaVideoRef.playVideo();
      page = '/video';
      playing = true;
      return;
    }
    if(introRef) {
      introRef.resumeExecution();
    }
    if(rotationAlertRef) {
      rotationAlertRef.resumeExecution();
    }
  }

  onMount(async () => {
    if(onDesktop) {
      await tick();
      desktopRef.init();
    }
    document.addEventListener("loader-loaded", async () => {
      // if (lowPowerMode) {
      //   await videoBackgroundRef.enterAnimation();
      //   videoBackgroundRef.videoLoaded();
      //   headerRef.enterAnimation();
      //   headerRef.enterLogo();
      //   lowPowerRef.enterAnimation()
      //   await footerRef.enterAnimation();
      //   introAnimated = true;
      // } else {
        introRef.setOpacity();
        await videoBackgroundRef.enterAnimation();
        videoBackgroundRef.videoLoaded();
        await introRef.enterAnimation();
        await introRef.introPulsation();
        headerRef.enterAnimation();
        headerRef.enterLogo();
        introRef.enterCircleInnerFigures();
        await footerRef.enterAnimation();
      // }
    });
  });

  async function onAllowed() {
    await videoBackgroundRef.hideVideoBg();
    await mediaVideoRef.playAnimation();
    allowed = true;
    playing = true;
  }

  let stepsMonitorRef;
  let stopped;

  async function onStopped() {
    if (!playing) {
      return stopCount = 0;
    }
    if(stopped) {
      return;
    }

    stopCount++;

    if (stopCount >= 15) {
      mediaVideoRef.stopVideo();
      stopped = true;
    }
  }

  async function onWalking() {
    if (!playing) {
      return stopCount = 0;
    }

    stopCount = 0;
    stopped = false;
    mediaVideoRef.resumeVideo();
  }

  export function onRotateFinished() {
    tapRef.enterAnimation();
  }

  export function hideHeaderAndFooter() {
    headerRef.exitAnimation();
    footerRef.exitAnimation();
  }

  export function showHeaderAndFooter() {
    headerRef.enterWithExit();
    footerRef.enterWithExit();
  }

  function onIntroAnimated() {
    introAnimated = true;
  }


  function videoEnd() {
    stepsMonitorRef.stopMonitoring();
    page = '/makingof';
    onWalking();
    playing = false;
  }

    function startMonitoring() {
      stepsMonitorRef.initMonitoring();
  }
</script>

<style>
  .main {
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    z-index: 1;
  }
</style>

<Header
  {page}
  on:hide-about-popup={hideAboutPopup}
  on:show-about-popup={showAboutPopup}
  bind:this={headerRef}
  {introAnimated} />

<main class="main">
  {#if !onDesktop}
    <VideoMedia
      on:playing={() => playing = true}
      on:waiting={() => playing = false}
      on:start-monitoring={startMonitoring}
      on:video-end={videoEnd}
      on:show-header-footer={showHeaderAndFooter}
      bind:this={mediaVideoRef} />
    <Intro
      on:intro-animated={onIntroAnimated}
      bind:this={introRef}
      on:show-rotation-alert={showRotationAlert} />
    <RotateAlert on:finished={onRotateFinished} bind:this={rotationAlertRef} />
    <Tap
      videoRef={mediaVideoRef}
      bind:this={tapRef}
      on:hide-content={hideHeaderAndFooter}
      on:allowed={onAllowed} />
    <VideoBackground
      bind:this={videoBackgroundRef}
    />
    <StepsMonitor
      on:stop={onStopped}
      on:step={onWalking}
      bind:this={stepsMonitorRef} />
  {:else}
    <Desktop
      bind:this={desktopRef}
      on:hide-about-popup={hideAboutPopup}
      on:show-about-popup={showAboutPopup} />
  {/if}
</main>
<Footer on:exit={showHeaderAndFooter} bind:this={footerRef} />

<About
  {onDesktop}
  bind:this={aboutRef}
  on:hide-about-popup={hideAboutPopup}
  on:show-about-popup={showAboutPopup} />
