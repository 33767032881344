<style lang="scss">
    .video-background-wrapper {
        position: fixed;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 2;
        min-width: 100vw;
        min-height: 100vh;
        background-color: navy
    }

    .video-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 52% 52%;
        opacity: 60%;;

        &--hidden {
            visibility: hidden;
            opacity: 0;
        }
    }
</style>

<script>
    import { TweenMax } from 'gsap'
    import { createEventDispatcher, onMount } from 'svelte';
    let videoRef;
    let videoWrapperRef;
    let mobSrc = "/blackout/img/404_mob_background.png";
    let lowPowerMode;
    let inited;

    const dispatch = createEventDispatcher();

    onMount(() => {
      videoRef.addEventListener('suspend', () => {
          lowPowerMode = true;
      });
      videoRef.addEventListener('playing', () => {
         lowPowerMode = false;
      });
    });

    export async function enterAnimation() {
        try {
            await videoRef.play();
            await videoRef.pause();
        } catch (e) {
            console.log(e);
        }
        const event = new Event('video-loaded');
        document.dispatchEvent(event);
    }

    export function videoLoaded() {
        videoRef.play();
        TweenMax.to(videoWrapperRef, 2, { opacity: 1, onComplete: () => {
        }})
    }

    export function hideVideoBg() {
        videoRef.pause();
        TweenMax.to(videoWrapperRef, .3, { opacity: 0, onComplete: () => {
            TweenMax.set(videoWrapperRef, { zIndex: -1 })
        }});
    }
</script>

<div class="video-background-wrapper" bind:this={videoWrapperRef}
      style="{ lowPowerMode ? `background: navy url('${mobSrc}');background-size: cover` : null }">
    <video src="https://myfirstbucket2111.s3.eu-central-1.amazonaws.com/Mobile.mp4" 
           bind:this={videoRef} playsinline autoplay muted loop 
           class="video-background {lowPowerMode ? 'video-background--hidden' : ''}" on:click={() => dispatch('show-header-footer')}>
    </video>
</div>
