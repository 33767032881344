<script>
  import { createEventDispatcher } from "svelte";
  import { TweenMax, TimelineMax, Linear, gsap } from "gsap";
  import CustomEase from "../vendor/gsap/CustomEase";
  gsap.registerPlugin(CustomEase);

  export let duration = 4;
  const dispatch = createEventDispatcher();

  let linesRightRef;
  let linesLeftRef;
  let rotateAlertRef;
  let entered;
  let mobile;
  let lineTimeline;

  CustomEase.create("mobile", ".83, -0, .12, .99");

  function onAnimationComplete() {
    exitAnimation();
  }

  function exitAnimation() {
    TweenMax.to(rotateAlertRef, 0.3, {
      opacity: 0,
      onComplete: () => {
        TweenMax.set(rotateAlertRef, { zIndex: -1 });
        dispatch("finished");
      }
    });
  }

  export function pauseExecution() {
    if (lineTimeline) {
      lineTimeline.pause();
    }
  }

  export function resumeExecution() {
    if (lineTimeline) {
      lineTimeline.play();
    }
  }
  export function hideContent() {
    const timeline = new TimelineMax();
    timeline.to(rotateAlertRef, 0.2, { opacity: 0, filter: "blur(0)" });
  }
  export function showContent() {
    const timeline = new TimelineMax();
    timeline.to(rotateAlertRef, 0.2, { opacity: 1, filter: "blur(1)" });
  }

  function listenForResize() {
    const resizeCallback = () => {
      const mql = window.matchMedia("(orientation: portrait)");
      if (!mql.matches) {
        onAnimationComplete();
        window.removeEventListener("resize", resizeCallback);
      }
    };

    window.addEventListener("resize", resizeCallback);
  }

  function infinityMobileAnimation() {
    const timeLine = new TimelineMax().repeat(-1);

    timeLine
      .to(
        mobile,
        0.6,
        { rotate: -90, "transform-origin": "28px 40px", ease: "mobile" },
        1
      )
      .to(
        mobile,
        0.5,
        { rotate: 0, "transform-origin": "28px 40px", ease: "mobile" },
        2
      );
  }

  export function enterAnimation() {
    if (entered) {
      return;
    }

    entered = true;

    infinityMobileAnimation();

    TweenMax.set(rotateAlertRef, { zIndex: 3 });
    lineTimeline = new TimelineMax({ onComplete: () => onAnimationComplete() });
    lineTimeline
      .to(rotateAlertRef, 0.2, { opacity: 1 })
      .to([linesLeftRef, linesRightRef], duration, {
        width: "50vw",
        ease: Linear.easeIn
      });

    listenForResize();
  }
</script>

<style lang="scss">
  .rotate-alert {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__inner {
      margin-top: 30px;
      height: 300px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__text {
      margin-top: 18px;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: white;
    }

    &__lines {
      position: absolute;
      bottom: 0;
      box-shadow: 0px 0px 6px 2px rgba(#ffffff, 0.6);
      display: flex;
      justify-content: center;

      &-left,
      &-right {
        background: white;
        height: 4px;
      }
    }
  }
</style>

<div class="rotate-alert" bind:this={rotateAlertRef}>
  <div class="rotate-alert__inner">
    <svg
      bind:this={mobile}
      width="37"
      height="65"
      viewBox="0 0 37 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.0742 64.6971C32.9679 64.6971 36.1356 61.5294 36.1356
        57.6357V7.86133C36.1356 3.96767 32.9679 0.8 29.0742 0.8H7.86133C3.96767
        0.8 0.8 3.96767 0.8 7.86133V57.6357C0.8 61.5294 3.96767 64.6971 7.86133
        64.6971L28.8742 64.6971H29.0742ZM2.70001 57.6357V7.86133C2.70001 5.01574
        5.01574 2.7 7.86134 2.7H29.0742C31.9198 2.7 34.2356 5.01573 34.2356
        7.86133V57.6357C34.2356 60.4813 31.9198 62.7971 29.0742
        62.7971H7.86133C5.01574 62.7971 2.70001 60.4813 2.70001 57.6357Z"
        fill="white"
        stroke="white"
        stroke-width="0.4" />
      <path
        d="M13.4531 7.29277H23.4824C24.0069 7.29277 24.4324 6.86729 24.4324
        6.34277C24.4324 5.81826 24.0069 5.39277 23.4824 5.39277H13.4531C12.9286
        5.39277 12.5031 5.81826 12.5031 6.34277C12.5031 6.86729 12.9286 7.29277
        13.4531 7.29277Z"
        fill="white"
        stroke="white"
        stroke-width="0.4" />
      <path
        d="M18.4639 52.8293C16.4613 52.8293 14.8342 54.4623 14.8342
        56.4678C14.8342 58.4733 16.4614 60.1053 18.4639 60.1053C20.4698 60.1053
        22.1014 58.4737 22.1014 56.4678C22.1014 54.4619 20.4699 52.8293 18.4639
        52.8293ZM18.4639 58.2053C17.5107 58.2053 16.7342 57.4265 16.7342
        56.4678C16.7342 55.508 17.5107 54.7293 18.4639 54.7293C19.4217 54.7293
        20.2014 55.5088 20.2014 56.4678C20.2014 57.4257 19.4218 58.2053 18.4639
        58.2053Z"
        fill="white"
        stroke="white"
        stroke-width="0.4" />
    </svg>
    <p class="rotate-alert__text">Please rotate your device</p>

    <div class="rotate-alert__lines">
      <div class="rotate-alert__lines-left" bind:this={linesLeftRef} />
      <div class="rotate-alert__lines-right" bind:this={linesRightRef} />
    </div>
  </div>
</div>
